import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import {
  ChangePassword,
  DeleteProfileImage,
  EditProfile,
  GetProfileData,
} from '../actions/profiles.action';
import { tap } from 'rxjs';

interface IProfilesStateModel {
  profileData: IProfileData | null;
}

@State<IProfilesStateModel>({
  name: 'ProfilesState',
  defaults: {
    profileData: null,
  },
})
@Injectable()
export class ProfilesState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static profileData(state: IProfilesStateModel) {
    return state.profileData;
  }

  @Action(GetProfileData)
  GetProfileData(ctx: StateContext<IProfilesStateModel>) {
    return this._http.post('user/view-profile', {}).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          profileData: resultData.profile,
        });
      })
    );
  }

  @Action(EditProfile)
  EditProfile(
    ctx: StateContext<IProfilesStateModel>,
    { param, editData }: EditProfile
  ) {
    return this._http.post('user/edit-profile', param).pipe(
      tap((apiResult) => {
        let oldData = ctx.getState().profileData;
        if (oldData) {
          let data: IProfileData = {
            name: editData.name,
            profile_img: editData.profile_img,
            email: editData.email,
            phone: editData.phone,
            company_name: oldData.company_name,
            department_name: oldData.department_name,
            title: oldData.title,
          };
          this._toastr.success(apiResult.response.status.msg, 'success', {
            closeButton: true,
            timeOut: 3000,
          });

          ctx.patchState({
            profileData: {
              ...data,
            },
          });
        }
      })
    );
  }

  @Action(ChangePassword)
  ChangePassword(
    ctx: StateContext<IProfilesStateModel>,
    { param }: ChangePassword
  ) {
    return this._http.post('user/change-password', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteProfileImage)
  DeleteProfileImage(
    ctx: StateContext<IProfilesStateModel>,
    { deleteImage }: DeleteProfileImage
  ) {
    return this._http.post('user/delete-profile-image', {}).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
        ctx.patchState({
          profileData: {
            ...deleteImage,
          },
        });
      })
    );
  }
}
