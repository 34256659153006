export class GetProfileData {
  static readonly type = '[GetProfileData] Post';
  constructor() {}
}

export class EditProfile {
  static readonly type = '[EditProfile] Post';
  constructor(public param: FormData, public editData: IProfileData) {}
}

export class ChangePassword {
  static readonly type = '[ChangePassword] Post';
  constructor(public param: IChangePassword) {}
}

export class DeleteProfileImage {
  static readonly type = '[DeleteProfileImage] Post';
  constructor(public deleteImage: IProfileData) {}
}
